




































































































































import SwapInsufficientGasStep from '@/components/swapToken/SwapInsufficientGasStep.vue'
import SwapLoadingTransactionStep from '@/components/swapToken/SwapLoadingTransactionStep.vue'
import SwapSwappedStep from '@/components/swapToken/SwapSwappedStep.vue'
import SwapUnexpectedErrorStep from '@/components/swapToken/SwapUnexpectedErrorStep.vue'
import BigNumber from 'bignumber.js'
import {Component, Watch} from 'vue-property-decorator'
import SwapDeadlineExceededStep from '@/components/swapToken/SwapDeadlineExceededStep.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {GetGasStep} from '@/enums/GetGasStep'
import GetGasFormStep from '@/components/getGas/GetGasFormStep.vue'
import {NeoHelper} from '@/helpers/NeoHelper'
import {GleederHelper} from '@/helpers/GleederHelper'
import {EnvHelper} from '@/helpers/EnvHelper'
import {bsNeo3} from '@/libs/bsNeo3'

type TransactionData = {
  amountToReceive: string | null
  amountToUse: string | null
}

@Component({
  components: {
    SwapDeadlineExceededStep,
    SwapUnexpectedErrorStep,
    SwapInsufficientGasStep,
    SwapSwappedStep,
    SwapLoadingTransactionStep,
    GetGasFormStep,
  },
  computed: {
    GetGasStep() {
      return GetGasStep
    },
  },
})
export default class GetGasModal extends MixinScreenSize {
  currentStep: GetGasStep = GetGasStep.FORM

  amountToReceive: string | null = null
  amountToUse: string | null = null
  neoOwned: string | null = null
  transactionHash: string | null = null

  interval: NodeJS.Timeout | null = null

  get canBeClosed() {
    return (
      this.currentStep !== GetGasStep.WAITING_SIGNATURE &&
      this.currentStep !== GetGasStep.VALIDATING_TRANSACTION
    )
  }

  get isProgressStep() {
    return this.currentStep !== GetGasStep.FORM
  }

  @Watch('amountToUse')
  async calculateGasAmountToEarn() {
    if (!this.amountToUse) {
      this.setAmountToReceive(null)
      return
    }

    const response = await GleederHelper.getAmountsOutFlamingo({
      amountIn: this.amountToUse,
      decimalsTokenToUse: EnvHelper.VUE_APP_BNEO_DECIMALS,
      hashTokenToUse: EnvHelper.VUE_APP_BNEO_SCRIPT_HASH,
      hashTokenToReceive: GleederHelper.gasScriptHash,
    })

    const gasOut = Number(
      (response.stack[0].value as {type: string; value: string}[])[1].value
    )

    this.amountToReceive = BigNumber(gasOut)
      // Decimals fixed back
      .shiftedBy(-EnvHelper.VUE_APP_BNEO_DECIMALS)
      // Business rule: we'll display for the user is always 0.5 less the value we received from RPC
      .minus(0.5)
      .toString()
  }

  @Watch('$store.state.walletConnect.n3Address')
  onN3AddressChange() {
    this.populateAccountBalance()
  }

  async closeEvent() {
    this.setAmountToReceive(null)
    this.setAmountToUse(null)
    this.setTransactionHash(null)
    this.setCurrentStep(GetGasStep.FORM)

    if (this.interval) {
      clearInterval(this.interval)
    }

    this.$emit('close')
  }

  async handleFormProceedClick() {
    if (!this.amountToUse || !this.amountToReceive) {
      return
    }

    this.setCurrentStep(GetGasStep.WAITING_SIGNATURE)

    try {
      this.setTransactionHash(
        await this.$walletAdapter.invokeN3Wallet({
          method: 'swapNeoUsingNeoProxyInvocation',
          params: {
            address: this.$walletAdapter.n3Address!,
            gasAmountOutMin: this.amountToReceive,
            neoAmountIn: this.amountToUse,
          },
        })
      )

      this.setCurrentStep(GetGasStep.VALIDATING_TRANSACTION)
      const isTransactionValid = await NeoHelper.validateTransaction(
        this.transactionHash!
      )

      if (!isTransactionValid) throw new Error()

      this.setCurrentStep(GetGasStep.SWAPPED)
    } catch (error) {
      this.setCurrentStep(GetGasStep.UNKNOWN_ERROR)
    }
  }

  async openEvent(transactionData: TransactionData | null) {
    await this.$await.run('populateAccountBalance', () =>
      this.populateAccountBalance()
    )

    if (transactionData?.amountToReceive && transactionData?.amountToUse) {
      this.setAmountToReceive(transactionData.amountToReceive)
      this.setAmountToUse(transactionData.amountToUse)

      await this.handleFormProceedClick()
    } else {
      this.setCurrentStep(GetGasStep.FORM)
    }

    this.$emit('open')
  }

  private setCurrentStep(step: GetGasStep) {
    this.currentStep = step
  }

  private async populateAccountBalance() {
    try {
      const address = this.$walletAdapter.n3Address

      if (!address) {
        this.setNeoOwned(null)
      } else {
        const accountBalance = await bsNeo3.blockchainDataService.getBalance(
          address
        )

        const neoBalance = accountBalance.find(
          item => item.token.hash === EnvHelper.VUE_APP_NEO_SCRIPT_HASH
        )

        this.setNeoOwned(neoBalance?.amount ?? '0')
      }
    } catch (error) {
      this.threatError(
        this.$translate('components.getGasModal.errors.unknownError')
      )
    }
  }

  private setAmountToUse(
    val: string | null,
    shouldSetAmountToReceive: boolean = true
  ) {
    this.amountToUse = this.toFixedOrEmpty(val)

    if (!shouldSetAmountToReceive) return

    if (!val) {
      this.setAmountToReceive(null, false)
      return
    }
  }

  private setAmountToReceive(
    val: string | null,
    shouldSetAmountToUse: boolean = true
  ) {
    this.amountToReceive = this.toFixedOrEmpty(val)

    if (!shouldSetAmountToUse) return

    if (!val) {
      this.setAmountToUse(null, false)
      return
    }
  }

  private toFixedOrEmpty(val: string | null) {
    if (!val) return ''

    return val.length === 0 ? '' : new BigNumber(val).toFixed()
  }

  private setTransactionHash(val: string | null) {
    this.transactionHash = val
  }

  private setNeoOwned(val: string | null) {
    this.neoOwned = val
  }

  private threatError(message: string) {
    this.$toast.abort(message)
    this.$modal.close('getGasModal')
  }
}
