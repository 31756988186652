

































































































































































































































































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import {MixinScreenSize} from './mixins/MixinScreenSize'
import GetGasModal from './getGas/GetGasModal.vue'
import ConnectWalletModal from '@/components/walletConnection/ConnectWalletModal.vue'
import {$} from '@/facade'

@Component({
  components: {
    ConnectWalletModal,
    QrcodeVue,
    GetGasModal,
  },
})
export default class SessionButton extends Mixins(MixinScreenSize) {
  @Prop({type: Boolean, default: false, required: false}) forceLarge!: boolean

  n3TruncatedAddress: string | null = null
  neoXTruncatedAddress: string | null = null

  dropdownSessionIsOpen = false
  modalIsOpen = false

  created() {
    this.onN3AddressChange(this.$walletAdapter.n3Address)
    this.onNeoXAddressChange(this.$walletAdapter.xAddress)
  }

  toggleModalSession() {
    this.dropdownSessionIsOpen = !this.dropdownSessionIsOpen
  }

  openGetGasModal() {
    this.$modal.open('getGasModal')
  }

  async disconnectN3Address() {
    this.$walletAdapter.disconnectN3Wallet()
  }

  async disconnectNeoXAddress() {
    this.$walletAdapter.disconnectXWallet()
  }

  connectNeoXAddress() {
    this.$modal.open('connectWalletModal')
  }

  connectN3Address() {
    this.$modal.open('connectWalletModal')
  }

  openModal() {
    this.$modal.open('connectWalletModal')
  }

  @Watch('$store.state.walletAdapter.modalIsOpen')
  onModalIsOpenChange(isOpen: boolean) {
    this.modalIsOpen = isOpen
  }

  @Watch('$store.state.walletAdapter.n3Address')
  onN3AddressChange(address: string | null) {
    this.n3TruncatedAddress = address?.trim()
      ? `${address.trim().slice(0, 5)}...${address.trim().slice(-4)}`
      : null
  }

  @Watch('$store.state.walletAdapter.xAddress')
  onNeoXAddressChange(address: string | null) {
    console.log('address', address)
    this.neoXTruncatedAddress = address?.trim()
      ? `${address.trim().slice(0, 5)}...${address.trim().slice(-4)}`
      : null
  }
}
