






















































































































































































































import {Component, Mixins} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {$} from '@/facade'
import Dropdown from '@/components/Dropdown.vue'
import DropdownItem from '@/components/DropdownItem.vue'
import SessionButton from '@/components/SessionButton.vue'

enum State {
  NORMAL,
  SIDEBAR_OPEN,
}

@Component({
  components: {DropdownItem, Dropdown, SessionButton},
})
export default class NavbarMenu extends Mixins(MixinScreenSize) {
  State = State
  state = State.NORMAL

  dropdownProjectsTitle = $.t('components.navbar.projects') as string
  dropdownDappText = $.t('components.navbar.dApps') as string
  dropdownAppText = $.t('components.navbar.apps') as string
  dropdownGasbotText = $.t('components.navbar.gasbot') as string
  dropdownResourcesTitle = $.t('components.navbar.resources') as string
  dropdownExplorersText = $.t('components.navbar.explorers') as string
  dropdownExchangesText = $.t('components.navbar.exchanges') as string
  dropdownCommunityActivityText = $.t(
    'components.navbar.communityActivity'
  ) as string
  dropdownWalletsText = $.t('components.navbar.wallets') as string

  isStateIn(...states: number[]) {
    return states.includes(this.state)
  }

  goToState(state: State) {
    if (this.state === state) {
      this.state = State.NORMAL
      return
    }

    this.state = state
  }

  resetState() {
    this.state = State.NORMAL
  }

  classLink(...names: string[]) {
    return {
      'navbar__link--active': names.includes(this.$route.name as string),
    }
  }
}
